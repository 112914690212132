<button
  #menuOverlay="overlay"
  class="app-header-switcher"
  type="button"
  [disabled]="!hasDropdown"
  [attr.aria-label]="ariaLabel"
  wMixpanel="Opened {{ ariaLabel }}"
  [wKeyboardNavigationItem]="{origin: 'left'}"
  [wOverlayPlacement]="{offsetY: 4, overlayX: 'left', overlayY: 'top', originX: 'left', originY: 'bottom'}"
  [wOverlay]="menuTmpl"
  [wOverlayDisabled]="!hasDropdown"
  (wOverlayBeforeOpen)="isOpened = true"
  (wOverlayAfterClose)="isOpened = false"
>
  @if (itemIconTemplateDirective) {
    <div class="app-header-switcher__icon">
      <ng-container
        [ngTemplateOutlet]="itemIconTemplateDirective.templateRef"
        [ngTemplateOutletContext]="{$implicit: selectedOption}"
      />
    </div>
  }

  <span class="app-header-switcher__title">{{ selectedOption.title }}</span>

  <w-svg-icon
    *ngIf="hasDropdown"
    class="app-header-switcher__expander"
    [class.app-header-switcher__expander_expanded]="isOpened"
    [iconId]="isOpened ? 'chevron-expanded' : 'chevron-collapsed'"
    [size]="8"
  />
</button>

<ng-template #menuTmpl>
  <w-app-header-dropdown>
    <w-app-menu
      [entityLabel]="entityLabel"
      [maxOptionsHeight]="maxOptionsHeight"
      [options]="options"
      [selectedValue]="selectedValue"
      [isServerFilter]="isServerFilter"
      [loading]="loading"
      (selectedValueChange)="menuOverlay.hide(); selectedValueChange.emit($event)"
      (queryChange)="queryChanged($event)"
      [wKeyboardNavigation]="{autofocus: true, cyclic: true}"
    >
      @if (itemIconTemplateDirective) {
        <ng-template let-option [wAppMenuItemIcon]="options">
          <ng-container
            [ngTemplateOutlet]="itemIconTemplateDirective.templateRef"
            [ngTemplateOutletContext]="{$implicit: option}"
          />
        </ng-template>
      }
    </w-app-menu>
  </w-app-header-dropdown>
</ng-template>
