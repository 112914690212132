<div
  class="app-sidebar app-sidebar_{{ isOpened ? 'expanded' : 'collapsed' }} app-sidebar_{{ theme }}"
  [class.app-sidebar_mobile]="isMobileLayout"
  role="navigation"
  aria-label="Main"
  (mouseenter)="openSidebarForDesktop()"
  (focusin)="openSidebarForDesktop()"
  (mouseleave)="closeSidebarForDesktop()"
  (focusout)="handleFocusOut($event)"
>
  <div #contentElement class="app-sidebar__content">
    <div class="app-sidebar__items" (wElementResize)="updateScrollbarVisibility(contentElement)">
      @for (item of items; track $index) {
        @if (item.type === 'separator') {
          <div class="app-sidebar__separator"></div>
        }

        @if (item.type === 'nav-item') {
          <a
            class="app-sidebar__item"
            [class.app-sidebar__item_active]="isActiveItem(item)"
            [routerLink]="item.routerUrl.path"
            [queryParams]="item.routerUrl.query"
            [fragment]="item.routerUrl.fragment"
            (click)="handleItemSelected()"
            wKeyboardNavigationItem
            wMixpanel="Navigate"
            [wMixpanelOptions]="{to: item.mixpanelLabel}"
          >
            <w-svg-icon class="app-sidebar__item-icon" [iconId]="item.iconId" [size]="14" />
            <span class="app-sidebar__item-label">{{ item.label }}</span>
          </a>
        }

        @if (item.type === 'group') {
          <button
            #sectionItem="wKeyboardNavigationItem"
            class="app-sidebar__item"
            [class.app-sidebar__item_active]="isActiveGroup(item)"
            type="button"
            [attr.aria-label]="expandedSections.has(item) ? 'Collapse section' : 'Expand section'"
            (click)="toggleSection(item)"
            wKeyboardNavigationItem
          >
            <w-svg-icon class="app-sidebar__item-icon" [iconId]="item.iconId" [size]="14" />
            <span class="app-sidebar__item-label">{{ item.label }}</span>
            <w-svg-icon
              class="app-sidebar__item-expander"
              [class.app-sidebar__item-expander_expanded]="expandedSections.has(item)"
              [iconId]="expandedSections.has(item) ? 'chevron-expanded' : 'chevron-collapsed'"
              [size]="10"
            />
          </button>

          @if (isOpened && expandedSections.has(item)) {
            <div @slideInOut class="app-sidebar__accordion-wrapper">
              <div class="app-sidebar__items app-sidebar__items_nested">
                @for (nestedItem of item.items; track $index) {
                  <a
                    class="app-sidebar__item"
                    [class.app-sidebar__item_active]="isActiveItem(nestedItem)"
                    [routerLink]="nestedItem.routerUrl.path"
                    [queryParams]="nestedItem.routerUrl.query"
                    [fragment]="nestedItem.routerUrl.fragment"
                    (click)="handleItemSelected(sectionItem)"
                    wKeyboardNavigationItem
                    wMixpanel="Navigate"
                    [wMixpanelOptions]="{to: nestedItem.mixpanelLabel}"
                  >
                    <span class="app-sidebar__item-label">{{ nestedItem.label }}</span>
                  </a>
                }
              </div>
            </div>
          }
        }
      }
    </div>
  </div>

  <div
    class="app-sidebar__footer"
    [class.app-sidebar__footer_with-separator]="hasVerticalScrollbar"
    [class.app-sidebar__footer_with-links]="links.length > 0"
  >
    <a class="app-sidebar__logo-link" href="/" wMixpanel="Logo" wKeyboardNavigationItem>
      @if (customLogoTemplate) {
        <ng-container [ngTemplateOutlet]="customLogoTemplate" [ngTemplateOutletContext]="{$implicit: isOpened}" />
      } @else {
        <w-svg-icon class="app-sidebar__logo" iconId="workato-logo" [size]="25" />
        <w-svg-icon class="app-sidebar__logo-text" iconId="workato-logo-text" [size]="75" />
      }
    </a>
    @if (links.length > 0) {
      <div class="app-sidebar__footer-links" [style.margin-left.px]="linksOffset">
        @for (link of links; track $index) {
          <a class="app-sidebar__footer-link" [href]="link.href">{{ link.name }}</a>
          @if (!$last) {
            <span class="app-sidebar__footer-link-separator"></span>
          }
        }
      </div>
    }
  </div>

  <button
    *ngIf="isMobileLayout && isOpened"
    class="app-sidebar__close-btn"
    type="button"
    (click)="closeSidebar()"
    wIconButton="close"
    wIconButtonAriaLabel="Close sidebar"
    [wIconButtonIconSize]="16"
  ></button>
</div>
