<button
  #menuOverlay="overlay"
  class="app-header-user"
  type="button"
  aria-label="Profile menu"
  wMixpanel="Open profile menu"
  wKeyboardNavigationItem
  [wOverlayPlacement]="{offsetY: 4, overlayX: 'right', overlayY: 'top', originX: 'right', originY: 'bottom'}"
  [wOverlay]="menuTmpl"
>
  <w-user-avatar class="app-header-user__avatar" [imgUrl]="userAvatar" [userName]="userName" [size]="24" />
</button>

<ng-template #menuTmpl>
  <w-app-header-dropdown>
    <w-app-user-menu (linkClick)="menuOverlay.hide()" [wKeyboardNavigation]="{autofocus: true, cyclic: true}" />
  </w-app-header-dropdown>
</ng-template>
