import _ from 'lodash';
import {Injectable} from '@angular/core';

import {Hash} from '@shared/types';

export enum ValidationType {
  REQUIRED = 'required',
  PHONE = 'phone_number',
  EMAIL = 'email',
  NUMERIC = 'numeric',
  INTEGER = 'integer',
  MIN = 'min',
  DOMAIN_LABEL = 'domain_label',
  HOSTNAME = 'hostname',
  PORT = 'port',
  MIN_LENGTH = 'min_length',
  MAX_LENGTH = 'max_length',
  MIN_MAX_LENGTH = 'min_max_length',
  RANGE = 'range',
  REGEXP = 'regexp',
}

export interface ValidationConfig {
  [fieldName: string]: FieldValidationConfig | FieldValidationConfig[];
}

export type FieldValidationConfig = ValidationType | ValidationRule;

export interface ValidationRule {
  type: ValidationType;
  message?: string;
  validator?: Validator;
}

type Validator = (value: any) => boolean;

export type ValidationErrors = Hash<string>;

/**
 * Validates object fields using provided rules and returns errors
 *
 * Example:
 *  const fields = {
 *    email: '',
 *    password: ''
 *  };
 *
 *  const config = {
 *   email: validate.type.REQUIRED,
 *   password: {
 *     type: validate.type.REQUIRED,
 *     message: 'It is a required field'
 *   },
 *   phone: [
 *     {type: validate.type.REQUIRED},
 *     {..other rules..}
 *   ];
 * };
 *
 *  const errors = validate(fields, config);
 *
 *  Result:
 *  {password: 'It is a required field', email: 'can't be blank'}
 */
@Injectable({
  providedIn: 'root',
})
export class Validation {
  static type = ValidationType;
  // TODO: expoting `type` as instance member can be removed after this service won't be used in ng1 anymore
  type = ValidationType;

  private messages = {
    [ValidationType.REQUIRED]: "can't be blank",
    [ValidationType.PHONE]: 'is invalid',
    [ValidationType.EMAIL]: 'is invalid',
    [ValidationType.NUMERIC]: 'must be a number',
    [ValidationType.INTEGER]: 'must be an integer',
    [ValidationType.DOMAIN_LABEL]:
      "can only contain lowercase alphabets, numbers, hyphens (-), can only begin with a letter, can't end with a hyphen and can't exceed 63 characters",
    [ValidationType.HOSTNAME]: 'is invalid',
    [ValidationType.PORT]: 'is invalid',
  };

  private phoneRegexp =
    /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/;

  private emailRegexp =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

  private domainLabelRegexp = /^(?![0-9-])[a-z0-9-]{0,62}[a-z0-9]$/;

  // One-domain (`localhost`) is invalid
  private hostnameRegexp =
    /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/;

  validate(fields: Hash<any>, config: ValidationConfig): ValidationErrors {
    return _.reduce<string, ValidationErrors>(
      _.keys(config),
      (errors, fieldName) => {
        // Should be array
        const rules = _.castArray<FieldValidationConfig>(config[fieldName]);
        const value = fields[fieldName];

        _.some(rules, (rule: FieldValidationConfig) => {
          const type = (rule as ValidationRule).type || (rule as ValidationType);
          const validator = (rule as ValidationRule)?.validator;

          if (validator) {
            const isValid = validator(value);

            if (!isValid) {
              errors[fieldName] = (rule as ValidationRule).message!;
            }

            return !isValid;
          }

          let hasError = false;

          switch (type) {
            case ValidationType.REQUIRED:
              hasError = !this.isRequired(value);
              break;
            case ValidationType.PHONE:
              hasError = !this.isPhone(value);
              break;
            case ValidationType.EMAIL:
              hasError = !this.isEmail(value);
              break;
            case ValidationType.NUMERIC:
              hasError = !this.isNumeric(value);
              break;
            case ValidationType.INTEGER:
              hasError = !this.isInteger(value);
              break;
            case ValidationType.DOMAIN_LABEL:
              hasError = !this.isDomainLabel(value);
              break;
            case ValidationType.HOSTNAME:
              hasError = !this.isHostname(value);
              break;
            case ValidationType.PORT:
              hasError = !this.isPort(value);
              break;
            default:
              throw new TypeError(`Unknown validation type "${type}"`);
          }

          if (hasError) {
            errors[fieldName] = (rule as ValidationRule).message || this.messages[type];
          }

          return hasError;
        });

        return errors;
      },
      {},
    );
  }

  minValidator(min: number): ValidationRule {
    return {
      type: ValidationType.MIN,
      validator: (value: any) => value > min,
      message: `must be greater than ${min}`,
    };
  }

  rangeValidator(min: number, max: number): ValidationRule {
    return {
      type: ValidationType.RANGE,
      validator: (value: any) => value >= min && value <= max,
      message: `must be between ${min} and ${max}`,
    };
  }

  minLengthValidator(min: number): ValidationRule {
    return {
      type: ValidationType.MIN_LENGTH,
      validator: (value: string) => value.length >= min,
      message: `length must be at least ${min} characters`,
    };
  }

  maxLengthValidator(max: number): ValidationRule {
    return {
      type: ValidationType.MAX_LENGTH,
      validator: (value: string) => value.length <= max,
      message: `length must be up to ${max} characters inclusive`,
    };
  }

  regexpValidator(regexp: RegExp): ValidationRule {
    return {
      type: ValidationType.REGEXP,
      validator: (value: string) => regexp.test(value),
      message: 'contains invalid characters',
    };
  }

  isRequired(value: any): boolean {
    return !_.isEmpty(value);
  }

  isPhone(value: any): boolean {
    return this.phoneRegexp.test(value || '');
  }

  isEmail(value: any): boolean {
    return this.emailRegexp.test(value || '');
  }

  isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }

  isInteger(value: any): boolean {
    return Number.isInteger(Number(value));
  }

  isDomainLabel(value: any): boolean {
    return this.domainLabelRegexp.test(value || '');
  }

  isHostname(value: any): boolean {
    return this.hostnameRegexp.test(value || '');
  }

  isPort(value: any): boolean {
    try {
      new URL(`https://t.t:${value}`);

      return true;
    } catch {
      return false;
    }
  }

  validationTypeToMessage(validationType: ValidationType): string {
    return this.messages[validationType];
  }
}
