<div>
  <ng-container *ngIf="teamInvitations.length === 1">
    You’ve been invited to join <strong>{{ teamInvitations[0].team_name }}</strong
    >.
  </ng-container>
  <ng-container *ngIf="teamInvitations.length > 1">
    You have <strong>{{ teamInvitations.length }}</strong> pending invitations.
  </ng-container>
</div>

<w-page-banner-button type="notice" [href]="href" (onClick)="handleInvitationClick()"
  >View {{ teamInvitations.length | wPlural: 'invite' : true }}</w-page-banner-button
>
