import {Injectable} from '@angular/core';
import adminIcon from '@icons/sidebar/admin.svg';
import toolsIcon from '@icons/sidebar/tools.svg';
import projectsIcon from '@icons/sidebar/projects.svg';
import workspaceIcon from '@icons/sidebar/teams.svg';
import operationsHubIcon from '@icons/sidebar/operations-hub.svg';
import communityIcon from '@icons/sidebar/community.svg';
import usageDashboardIcon from '@icons/sidebar/usage-dashboard.svg';
import orgConsoleIcon from '@icons/sidebar/organization-console.svg';
import adminConsoleIcon from '@icons/sidebar/manage-customers.svg';
import platformIcon from '@icons/sidebar/platform.svg';
import lampIcon from '@icons/lamp.svg';
import accessIcon from '@icons/access.svg';
import {BehaviorSubject, Observable} from 'rxjs';
import _ from 'lodash';

import {replaceString} from '@shared/utils/replace-string';

import {AuthUser, AvailableTool} from '../../services/auth-user';
import {AccountEnvironmentService} from '../../services/account-environments.service';
import {getUsageDashboardPath} from '../../modules/usage-dashboard/usage-dashboard.helpers';
import {OemService} from '../../modules/oem/oem.service';
import {AuthUserPrivilegeExpression} from '../../services/auth-user.types';

import {AppNavItem, AppSidebarGroup, AppSidebarItem} from './app-sidebar.types';

const OEM_AC_READ_PRIVILEGES: AuthUserPrivilegeExpression = {
  operator: 'or',
  operands: ['oem_ac_analytics.read', 'oem_ac_customers.read', 'oem_ac_automations.read'],
};

const PLATFORM_TOOLS_KEYS: Array<AvailableTool['id']> = [
  'service_console',
  'topic',
  'workbot',
  'lcap_portal',
  'usage_insights',
];

export const DESKTOP_SIDEBAR_TOGGLE_DELAY = 250;

@Injectable({providedIn: 'root'})
export class AppSidebarService {
  isSidebarOpened$: Observable<boolean>;

  private isSidebarOpened = new BehaviorSubject<boolean>(false);

  constructor(
    private oemService: OemService,
    private authUser: AuthUser,
    private accountEnvironments: AccountEnvironmentService,
  ) {
    this.isSidebarOpened$ = this.isSidebarOpened.asObservable();
  }

  openSidebar() {
    this.isSidebarOpened.next(true);
  }

  closeSidebar() {
    this.isSidebarOpened.next(false);
  }

  buildSidebar(): AppSidebarItem[] {
    if (!this.authUser.authenticated) {
      return [];
    }

    const items: AppSidebarItem[] = [];

    const platformGroup = this.buildPlatformGroup();
    const toolsGroup = this.buildToolsGroup();
    const adminGroup = this.buildAdminGroup();

    items.push({
      type: 'nav-item',
      iconId: projectsIcon.id,
      label: this.oemService.mixedAssets?.navigation_name ?? 'Projects',
      mixpanelLabel: 'Projects',
      routerUrl: {path: '/recipes', query: {fid: 'projects'}},
      alsoActiveFor: this.convertPathPatterns(['/', '/recipes', '/recipes/*', '/connections/*', '/data_tables/*']),
    });

    items.push({
      type: 'nav-item',
      iconId: operationsHubIcon.id,
      label: 'Operations hub',
      mixpanelLabel: 'Operations hub',
      routerUrl: {path: '/dashboard'},
    });

    if (!this.oemService.communityNavItemHidden) {
      items.push({
        type: 'nav-item',
        iconId: communityIcon.id,
        label: 'Community library',
        mixpanelLabel: 'Community Recipes',
        routerUrl: {path: '/browse'},
      });
    }

    if (platformGroup.items.length) {
      items.push(platformGroup);
    }

    if (toolsGroup.items.length || this.hasUnavailableTools) {
      items.push(toolsGroup);
    }

    items.push({type: 'separator'});

    if (this.authUser.hasPrivilege('federation_management.read')) {
      items.push({
        type: 'nav-item',
        iconId: orgConsoleIcon.id,
        label: 'Automation HQ',
        mixpanelLabel: 'Automation HQ',
        routerUrl: {path: '/organization_console'},
      });
    }

    if (this.hasOemAdminConsole) {
      items.push({
        type: 'nav-item',
        iconId: adminConsoleIcon.id,
        label: 'Manage customers',
        mixpanelLabel: 'Manage customers',
        routerUrl: {path: '/admin_console'},
      });
    }

    if (adminGroup.items.length) {
      items.push(adminGroup);
    }

    if (this.hasWorkspaceAdminTool) {
      items.push({
        type: 'nav-item',
        label: 'Workspace admin',
        mixpanelLabel: 'Workspace admin',
        iconId: workspaceIcon.id,
        routerUrl: {path: '/members'},
      });
    }

    const usageDashboardPath = getUsageDashboardPath(this.authUser);

    if (usageDashboardPath) {
      items.push({
        type: 'nav-item',
        label: 'Usage',
        mixpanelLabel: 'Usage dashboard',
        iconId: usageDashboardIcon.id,
        routerUrl: {path: usageDashboardPath},
      });
    }

    return items;
  }

  buildAgenticSidebar(): AppSidebarItem[] {
    return [
      {
        type: 'nav-item',
        label: 'Genies',
        routerUrl: {path: '/'},
        iconId: lampIcon.id,
        mixpanelLabel: 'Genies',
      },
      {
        type: 'nav-item',
        label: 'User access',
        routerUrl: {path: '/access'},
        iconId: accessIcon.id,
        mixpanelLabel: 'User Access',
      },
    ];
  }

  private get hasOemAdminConsole(): boolean {
    return (
      this.authUser.oem_admin &&
      (this.authUser.hasPrivilege(OEM_AC_READ_PRIVILEGES) ||
        (this.authUser.hasPrivilege(['oem_ac_analytics.manage']) &&
          (this.authUser.hasPrivilege('oem_vendor') || this.authUser.hasPrivilege('audit_log_replication'))))
    );
  }

  private get hasUnavailableTools(): boolean {
    return !this.authUser.team_session && this.authUser.has_unavailable_tools;
  }

  private get hasWorkspaceAdminTool(): boolean {
    return Boolean(this.authUser.available_tools.find(tool => tool.id === 'team'));
  }

  private buildPlatformGroup(): AppSidebarGroup {
    const group: AppSidebarGroup = {
      type: 'group',
      label: 'Platform',
      iconId: platformIcon.id,
      items: this.authUser.available_tools
        .filter(tool => PLATFORM_TOOLS_KEYS.includes(tool.id))
        .map(tool => ({
          label: tool.name,
          mixpanelLabel: tool.name,
          routerUrl: {path: tool.href},
        })),
    };

    group.items.sort(this.compareItems);

    return group;
  }

  private buildToolsGroup(): AppSidebarGroup {
    const group: AppSidebarGroup = {
      type: 'group',
      label: 'Tools',
      iconId: toolsIcon.id,
      items: this.authUser.available_tools
        .filter(tool => !PLATFORM_TOOLS_KEYS.includes(tool.id) && tool.id !== 'team')
        .map(tool => ({
          label: tool.name,
          mixpanelLabel: tool.name,
          routerUrl: {path: tool.href},
        })),
    };

    group.items.sort(this.compareItems);

    if (this.hasUnavailableTools && !this.accountEnvironments.isSecondary) {
      group.items.push({
        routerUrl: {path: '/tools'},
        label: 'Discover more tools',
        mixpanelLabel: 'Discover more tools',
      });
    }

    return group;
  }

  private buildAdminGroup(): AppSidebarGroup {
    const group: AppSidebarGroup = {
      type: 'group',
      label: 'Admin',
      iconId: adminIcon.id,
      items: [],
    };

    if (this.authUser.hasRole('content_admin')) {
      group.items.push({
        routerUrl: {path: '/unsupported_connectors'},
        label: 'Manage unsupported connectors',
        mixpanelLabel: 'Manage unsupported connectors',
      });
    }

    if (this.authUser.hasRole('adapter_prospects_admin')) {
      group.items.push({
        routerUrl: {path: '/community_admin'},
        label: 'SDK Community admin',
        mixpanelLabel: 'SDK Community admin',
      });
    }

    group.items.sort(this.compareItems);

    return group;
  }

  private convertPathPatterns(patterns: string | string[]): RegExp[] {
    return _.castArray(patterns).map(pattern => {
      const regexp = replaceString(pattern, '*', {
        replaceMatched: () => '.+?',
        replaceUnmatched: str => _.escapeRegExp(str),
      });

      return new RegExp(`^${regexp}$`, 'i');
    });
  }

  private compareItems = (item1: AppNavItem, item2: AppNavItem): number =>
    item1.label && item2.label ? item1.label.localeCompare(item2.label) : 0;
}
