import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {escape} from 'lodash';
import {Observable, Subject} from 'rxjs';
import dataPipelineIcon from '@icons/resource/data_pipeline.svg';

import {HttpResource, Request} from '@shared/services/http-resource';

import {Folder} from '../../modules/folders/folders.types';
import {RootDialogService} from '../../services/root-dialog.service';
import {RedirectedResponse} from '../../types';
import {makeRedirectedRequest} from '../../utils/make-redirected-request';

import {
  DataPipeline,
  DataPipelineObjectSync,
  DataPipelineObjectSyncDetailed,
  DataPipelineObjectSyncState,
  DataPipelineObjectSyncType,
  DataPipelineRunningState,
} from './data-pipeline.types';
import {DataPipelineFiltersDateRange} from './data-pipeline-syncs-tab/data-pipeline-syncs-tab.types';

export interface DataPipelineSyncsResponse {
  has_next_page: boolean;
  has_previous_page: boolean;
  syncs: DataPipelineObjectSync[];
}

export interface DataPipelineSyncsParams {
  offset_object_sync_id?: string;
  next?: true;
  prev?: true;
  date_from?: string;
  date_to?: string;
  started_at?: DataPipelineFiltersDateRange;
  state?: DataPipelineObjectSyncState;
  sync_type?: DataPipelineObjectSyncType;
  object_name?: string;
}

type DataPipelineUpdate = 'updated' | 'cloned' | 'started' | 'stopped';

@Injectable({
  providedIn: 'root',
})
export class DataPipelineService {
  updated$: Observable<DataPipelineUpdate>;

  private updated = new Subject<DataPipelineUpdate>();
  private resource: HttpResource;
  private syncsResource: HttpResource;

  constructor(
    private http: HttpClient,
    private dialog: RootDialogService,
  ) {
    this.resource = new HttpResource(this.http, {
      url: '/web_api/data_pipelines/{{id}}/{{action}}.json',
    });

    this.syncsResource = new HttpResource(this.http, {
      url: '/web_api/data_pipelines/{{pipelineId}}/syncs/{{syncId}}',
    });

    this.updated$ = this.updated.asObservable();
  }

  get(id: DataPipeline['id']): Request<DataPipeline> {
    return this.resource.get({id});
  }

  create(name: string, projectFolderId: number): Promise<DataPipeline> {
    return this.resource.create<DataPipeline>({name, folder_id: projectFolderId});
  }

  async update(id: DataPipeline['id'], props: Partial<DataPipeline>): Promise<void> {
    const response = await this.resource.update({id}, props);

    this.updated.next('updated');

    return response;
  }

  moveToFolder(id: DataPipeline['id'], folderId: Folder['id']): Promise<void> {
    return this.update(id, {folder_id: folderId});
  }

  delete(id: DataPipeline['id']): Promise<void> {
    return this.resource.delete({id});
  }

  async clone(id: DataPipeline['id']): Promise<RedirectedResponse> {
    const response = await makeRedirectedRequest(
      this.http.post(this.resource.url({id, action: 'clone'}), {}, {observe: 'response'}),
    );

    this.updated.next('cloned');

    return response;
  }

  async start(id: DataPipeline['id']): Promise<DataPipelineRunningState> {
    const response = await this.resource.get({id, action: 'start'});

    this.updated.next('started');

    return response;
  }

  async stop(id: DataPipeline['id']): Promise<DataPipelineRunningState> {
    const response = await this.resource.get({id, action: 'stop'});

    this.updated.next('stopped');

    return response;
  }

  getSyncs(pipelineId: DataPipeline['id'], params?: DataPipelineSyncsParams): Request<DataPipelineSyncsResponse> {
    return this.syncsResource.get({pipelineId}, {query: params});
  }

  getSync(
    pipelineId: DataPipeline['id'],
    syncId: DataPipelineObjectSync['id'],
  ): Request<DataPipelineObjectSyncDetailed> {
    return this.syncsResource.get({pipelineId, syncId});
  }

  isActive(dataPipeline: Pick<DataPipeline, 'running'>): boolean {
    return Boolean(dataPipeline.running);
  }

  async showDeleteConfirmation(dataPipeline: Pick<DataPipeline, 'name'>): Promise<boolean> {
    return this.dialog.openConfirmationDialog(
      'Permanently delete data pipeline?',
      `Deleting <b>${escape(
        dataPipeline.name,
      )}</b>.<br/>Data pipeline can’t be recovered from Trash, so this action can’t be undone.`,
      {
        width: 624,
        confirmButton: 'Delete pipeline',
        cancelButton: 'Cancel',
        headerIcon: dataPipelineIcon.id,
        theme: 'negative',
      },
    );
  }
}
