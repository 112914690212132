import {NgModule} from '@angular/core';

import {RouterModule} from '@shared/modules/router/router.module';

import {AppRoute} from '../../types';
import {SharedModule} from '../../shared.module';

import {PageLoadErrorComponent} from './page-load-error/page-load-error.component';
import {PageErrorComponent} from './page-error/page-error.component';

const routes: AppRoute[] = [
  {
    path: 'page-load-error',
    component: PageLoadErrorComponent,
    data: {
      title: 'Error loading the page',
      mixpanelRouteName: 'Error page',
    },
  },
  {
    path: ':errorCode',
    component: PageErrorComponent,
    data: {
      mixpanelRouteName: 'Error page',
    },
  },
  {
    path: '**',
    component: PageErrorComponent,
    data: {
      mixpanelRouteName: 'Error page',
    },
  },
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [PageLoadErrorComponent, PageErrorComponent],
  exports: [PageErrorComponent],
})
export class ServicePagesModule {}
