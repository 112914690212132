import {Injectable} from '@angular/core';

import {Overlay} from '@shared/modules/overlay/overlay.service';
import {MixpanelService} from '@shared/services/mixpanel';

import {SplashScreenComponent} from '../components/splash-screen/splash-screen.component';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesService {
  constructor(
    private overlay: Overlay,
    private mixpanelService: MixpanelService,
  ) {}

  showWorkspaceSwitchSlashScreen(workspaceName: string) {
    this.overlay.show(SplashScreenComponent, {
      props: {message: `Switching to ${workspaceName} account.`},
    });
  }

  switchToWorkspace(workspaceId: number, teamName: string, mixpanelPrefix?: string) {
    const id = encodeURIComponent(workspaceId);
    const name = encodeURIComponent(teamName);

    const mixpanelEventName = `${mixpanelPrefix ? `${mixpanelPrefix}: ` : ''}Switched to workspace`;

    this.mixpanelService.track(
      mixpanelEventName,
      {
        id: workspaceId,
      },
      () => {
        window.location.href = `/users/switch_team?team_id=${id}&team_name=${name}`;
      },
    );
  }
}
