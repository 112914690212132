import {MarkdownOptions} from '@shared/services/markdown.service';
import {BreadcrumbDescriptor} from '@shared/components/breadcrumbs/breadcrumbs.types';

export const PROVIDER_TO_CHAT_INTERFACE = {
  slack: 'workato_genie_slack',
  ms_teams: 'workato_genie_ms_teams',
};

export const CUSTOM_OAUTH_MARKDOWN_CONFIG: MarkdownOptions = {
  gfm: false,
  linksTarget: '_blank',
  allowedAttrs: 'id href target',
};

// genie name is limited by slack manifest name field
export const GENIE_NAME_MAX_LENGTH = 35;
// only letters, numbers and spaces are allowed
export const GENIE_NAME_REGEXP = /^[a-zA-Z0-9 ]+$/;
export const GENIE_NAME_REGEXP_VALIDATION_MESSAGE = 'Name uses special characters';
export const GENIE_NAME_VALIDATION_MESSAGE = `Name exceeds ${GENIE_NAME_MAX_LENGTH} characters, or uses special characters`;
export const ALL_GENIES_BREADCRUMB: BreadcrumbDescriptor = {
  type: 'link',
  link: '/',
  text: 'All genies',
};
