import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

import {PageBannersService} from '../../../modules/page-banners/page-banners.service';
import {AuthUser} from '../../../services/auth-user';
import {AuthUserInvitation} from '../../../services/auth-user.types';
import {AbstractBannerComponent} from '../abstract-banner.component';

@Component({
  selector: 'w-team-invitations-banner',
  templateUrl: './team-invitations-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamInvitationsBannerComponent extends AbstractBannerComponent implements OnInit {
  teamInvitations: AuthUserInvitation[];

  constructor(
    private authUser: AuthUser,
    private pageBannersService: PageBannersService,
  ) {
    super();
  }

  ngOnInit() {
    this.teamInvitations = this.authUser.invitations!;
  }

  get href(): string {
    return this.authUser.buildOrchestrateUrl('/users/current/edit/team_membership');
  }

  handleInvitationClick() {
    this.pageBannersService.remove(this.bannerId);
  }
}
