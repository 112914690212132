import {User} from './user';

export enum SystemRoleName {
  ADMIN = 'Admin',
  OPERATOR = 'Operator',
  ANALYST = 'Analyst',
  OWNER = 'Owner',
  WORKSPACE_OWNER = 'Workspace Owner',
  NO_ACCESS = 'No Access',
}

export interface Role<TId> {
  id: TId;
  name: string;
  system: boolean;
  no_access?: boolean; // special case for Environments feature for NoAccess role
}

export interface Member {
  id?: User['id'];
  name: User['name'];
  email: NonNullable<User['email']>;
  avatar_url: User['avatar_url'];
  description?: string;
}

export type MemberGrantType = 'team' | 'customer_manager' | 'federation_manager';
