import _ from 'lodash';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {HttpResource, Request} from '@shared/services/http-resource';

import {CountryForPayment, RedirectedResponse, User} from '../types';
import {makeRedirectedRequest} from '../utils/make-redirected-request';
import {WorkspaceUsageStatsRequestParams} from '../pages/federation-admin/federation-admin.types';
import {TeamMemberInvitation} from '../pages/team-members/team-members.types';

interface UserData {
  user: User;
  avatar_small: string;
  avatar_medium: string;
  redirect_to: string;
}

interface ResetDataEmail {
  email: string;
}

interface ResetDataToken {
  reset_password_token: string;
}

export interface SupportSessionParams {
  email: string;
  password: string;
}

export interface TaskStatsDataInterval {
  time: string;
  action_count: number;
  proxy_requests_count?: number;
  environments?: {
    [key: string]: {
      action_count: number;
      proxy_requests_count?: number;
    };
  };
}

export interface TaskStatsData {
  yearly?: {
    intervals: TaskStatsDataInterval[];
  };
}

export type TaskUsageRequestParams = Omit<WorkspaceUsageStatsRequestParams, 'data_type'>;

export interface Invites {
  invitations: TeamMemberInvitation[];
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  protected resource: HttpResource;
  private notificationsResource: HttpResource;

  private countryList: CountryForPayment[];

  constructor(protected http: HttpClient) {
    this.resource = new HttpResource(this.http, {url: '/users/{{id}}/{{action}}.json'});
    this.notificationsResource = new HttpResource(this.http, {
      url: '/user_notifications/{{action}}',
    });
  }

  update(params: {id: User['id']; user: Partial<User>}): Request<UserData> {
    return this.resource.put({id: params.id}, _.omit(params, 'id'));
  }

  async signUp(params: {user: Partial<User>}): Promise<RedirectedResponse> {
    return makeRedirectedRequest(this.http.post(this.resource.url(), params, {observe: 'response'}));
  }

  async signIn(params: {user: Partial<User>}): Promise<RedirectedResponse> {
    return makeRedirectedRequest(this.http.post(this.resource.url({action: 'sign_in'}), params, {observe: 'response'}));
  }

  async resetPassword(params: ResetDataEmail | ResetDataToken): Promise<RedirectedResponse> {
    return makeRedirectedRequest(
      this.http.post(this.resource.url({action: 'password'}), {user: params}, {observe: 'response'}),
    );
  }

  async resetDelegatedAdminPassword(params: {email: string}): Promise<RedirectedResponse> {
    return makeRedirectedRequest(
      this.http.post(
        this.resource.url({action: 'support_session_password_create'}),
        {user: params},
        {observe: 'response'},
      ),
    );
  }

  async startSupportSession(user: SupportSessionParams): Promise<void> {
    return this.resource.post({action: 'support_session_create'}, {user});
  }

  async setPassword(params: {password: string; password_confirmation: string}): Promise<RedirectedResponse> {
    return makeRedirectedRequest(
      this.http.post(this.resource.url({action: 'set_password'}), {user: params}, {observe: 'response'}),
    );
  }

  async updatePassword(params: {
    password: string;
    password_confirmation: string;
    reset_password_token: string;
  }): Promise<RedirectedResponse> {
    return makeRedirectedRequest(
      this.http.put(this.resource.url({action: 'password'}), {user: params}, {observe: 'response'}),
    );
  }

  async updateExpiredPassword(params: {
    current_password: string;
    password: string;
    password_confirmation: string;
  }): Promise<RedirectedResponse> {
    return makeRedirectedRequest(
      this.http.put(this.resource.url({action: 'password_expired'}), {user: params}, {observe: 'response'}),
    );
  }

  async updateDelegatedAdminPassword(params: {
    support_session_password: string;
    support_session_password_confirmation: string;
    reset_support_session_password_token: string;
  }): Promise<RedirectedResponse> {
    return makeRedirectedRequest(
      this.http.put(
        this.resource.url({action: 'support_session_password_update'}),
        {user: params},
        {observe: 'response'},
      ),
    );
  }

  resendConfirmationEmail(): Request<void> {
    return this.resource.post({action: 'resend_confirmation_email'});
  }

  async getCountryList(): Promise<CountryForPayment[]> {
    if (!this.countryList) {
      this.countryList = await this.resource.get({action: 'country_list'});
    }

    return this.countryList;
  }

  async verifyPassword(password: string): Promise<boolean> {
    return this.resource.post({action: 'verify_password'}, {password});
  }

  async getTaskUsage(): Promise<TaskStatsData> {
    return this.resource.get({id: 'current', action: 'task_stats'});
  }

  async getApiProxyTaskUsage(): Promise<TaskStatsData> {
    return this.resource.get({id: 'current', action: 'proxy_task_stats'});
  }

  disableActionUsageNotification(notificationKey: string): Request<void> {
    return this.notificationsResource.put(
      {action: 'action_count_threshold_reached'},
      {user_notification: {key: notificationKey, notified: true}},
    );
  }

  async getInvites(): Promise<Invites> {
    return this.resource.get({action: 'invites'});
  }
}
