export type SuggestionType = 'method' | 'function' | 'operator' | 'pill';

export type SuggestionCategory = 'string' | 'array' | 'date' | 'number' | 'conditional' | 'conversion';

export interface SimpleParameter {
  name: string;
  description?: string;
}

export interface ComplexParameter {
  left: SimpleParameter;
  right: SimpleParameter;
}

export type FunctionParameter = SimpleParameter | ComplexParameter;

export interface PrimaryCodeExample {
  input?: {
    type: RubyType;
    value: string;
  };
  code: string;
  output: {
    type: RubyType;
    value: string;
  };
}

export interface FunctionValidator {
  type: string;
  allowed_values?: Array<number | string | boolean>;
}

export interface FunctionCallSignatureOperand<TType extends string> {
  types: TType[];
  validators: FunctionValidator[];
  vararg: boolean; // Means that this is the last argument that can be repeated multiple times, kinda like rest operator (arg1, arg2, ...args)
}

export interface FunctionCallSignature<TType extends string> {
  operands: Array<FunctionCallSignatureOperand<TType>>;
  return_type: TType;
}

export interface FormulaSuggestion<TType extends string> {
  name: string;
  /**
   * api_name is the name that is used on the backend, while the name is the string representation of the function/operator when used in a expression
   * Not used for ruby suggestions
   * For insights suggestions this will be the name of an actual function, e.g. `PLUS(1, 2)` for `1 + 2` binary expression or `DATE_TRUNC(...)` `for date_trunc(...)` function call
   */
  api_name?: string;
  value?: string;
  type?: Exclude<SuggestionType, 'pill'>;
  options: {
    description: string;
    categories?: SuggestionCategory[];
    global?: boolean;
    operator?: boolean;
    result_type?: TType;
    types?: TType[];
    params?: FunctionParameter[];
    usage?: number;
    primaryExample?: PrimaryCodeExample;
    examples?: string[];
    search_tags?: string[];
    quick_tips?: string[];
    more_link?: string;
    signatures?: Array<FunctionCallSignature<TType>>;
  };
}

export enum RubyType {
  STRING = 'string',
  DATE_TIME = 'date_time',
  DATE = 'date',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  INTEGER = 'integer',
  FLOAT = 'float',
  ARRAY = 'array',
  HASH = 'hash',
  BINARY = 'binary',
  UNIT_OF_TIME = 'unit_of_time',
}
