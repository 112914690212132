/** @description join parts ['a', 'b', 'c', 'd'] to get 'a, b, c and d' string.
 */

export enum ToSentenceSeparator {
  AND = 'and',
  OR = 'or',
}

const separatorValues: Record<ToSentenceSeparator, string> = {
  [ToSentenceSeparator.AND]: $localize`:And separator, example a and b and c:and`,
  [ToSentenceSeparator.OR]: $localize`:Or separator, example a or b or c:or`,
};

const COMMA_SEPARATOR = $localize`:Comma separator, example a, b, c:,`;
const DOT_SEPARATOR = $localize`:Dot separator, example A. B. C:.`;

export const COMMA_SEPARATOR_WITH_SPACE = `${COMMA_SEPARATOR} `;
export const DOT_SEPARATOR_WITH_SPACE = `${DOT_SEPARATOR} `;

export function toSentence(parts: readonly string[], separator: ToSentenceSeparator = ToSentenceSeparator.AND): string {
  const separatorValue = separatorValues[separator];

  if (parts.length < 3) {
    return parts.join(` ${separatorValue} `);
  }

  return $localize`:Joins multiple array items into a string using comma and "and / or" separator, example a, b, c and d:${parts.slice(0, -1).join(COMMA_SEPARATOR_WITH_SPACE)} ${separatorValue} ${parts[parts.length - 1]}`;
}
