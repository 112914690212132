import {Genie, Recipe, RecipeStep} from '../../types';

/*
 * In Agentic users can't change/remove/paste trigger in a skill recipe
 */

export function isSkillRecipe(recipe: Pick<Recipe, 'genie' | 'knowledge'>): boolean {
  return Boolean(recipe?.genie && !recipe.knowledge);
}

export function isSkillTrigger(step: RecipeStep, recipe: Pick<Recipe, 'genie' | 'knowledge'>): boolean {
  return isSkillRecipe(recipe) && step.keyword === 'trigger';
}

export function isKnowledgeRecipe(recipe: Pick<Recipe, 'genie' | 'knowledge'>): boolean {
  return Boolean(recipe.genie && recipe.knowledge);
}

export function isKnowledgeTrigger(step: RecipeStep, recipe: Pick<Recipe, 'genie' | 'knowledge'>): boolean {
  return isKnowledgeRecipe(recipe) && step.keyword === 'trigger';
}

export function isGenieActive(genie?: Pick<Genie, 'state'>): boolean {
  return genie?.state === 'active';
}
