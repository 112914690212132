import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';

import {LayoutService} from '@shared/services/layout.service';
import {MixpanelService} from '@shared/services/mixpanel';

import {GuestHeaderLink} from '../header.types';
import {RouterHelpers} from '../../../services/router-helpers.service';

import {headerLinks} from './header-links';

@Component({
  selector: 'w-guest-header',
  templateUrl: './guest-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MixpanelService],
})
export class GuestHeaderComponent {
  navigationLinks: GuestHeaderLink[];
  isCompactView$: Observable<boolean>;

  constructor(
    private layoutService: LayoutService,
    private routerHelpers: RouterHelpers,
    private mixpanelService: MixpanelService,
  ) {
    this.mixpanelService.init({featureName: 'Guest header navigation', prependRoute: false});
    this.navigationLinks = headerLinks;
    this.isCompactView$ = this.layoutService.isCompactView$;
  }

  get requestDemoLink(): string {
    let link = '/request_demo';
    const isOnRecipePage = this.routerHelpers.isCurrentlyOnPage('recipe');

    if (isOnRecipePage) {
      link += '?utm_content=recipes_nav';
    }

    return link;
  }
}
