import {times} from 'lodash';

import {buildMessageFormatter} from '@shared/utils/i18n/build-message-formatter';

import {PicklistItem, SelectFieldWithOptions, TextField} from '../../types';

import {IfCondition, Keywords} from './app-dictionary';

const retryTimesFormatter = buildMessageFormatter<{retryTimes: number}>(
  $localize`{retryTimes, plural, =0 {Do not retry} one {Retry # time} other {Retry # times}}`,
);

const retryIntervalFormatter = buildMessageFormatter<{retryInterval: number}>(
  $localize`{retryInterval, plural, one {# second} other {# seconds}}`,
);

const repeatModeHintFormatter = buildMessageFormatter<void, 'b'>(
  $localize`When set to <b>Batch of items</b>, the <b>Input list</b> is repeated in batches of specified size`,
);

const clearScopeHintFormatter = buildMessageFormatter<void, 'a' | 'b'>(
  $localize`Typically <b>no</b> for most use cases. <a>Learn more</a>`,
);

const maxRetryCountHintFormatter = buildMessageFormatter<void, 'b'>(
  $localize`After all retry attempts fail, actions in the <b>Error</b> block will be processed`,
);

const retryIntervalHintFormatter = buildMessageFormatter<void, 'b'>(
  $localize`Time to wait before retrying actions in <b>Monitor</b> block`,
);

export const IF_CONDITIONS: IfCondition[] = [
  {
    name: 'contains',
    title: $localize`contains`,
    description: $localize`Does field contain value? Accepts types: Array, String`,
  },
  {
    name: 'starts_with',
    title: $localize`starts with`,
    description: $localize`Does field start with value? Accepts types: String`,
  },
  {
    name: 'ends_with',
    title: $localize`ends with`,
    description: $localize`Does field end with value? Accepts types: String`,
  },
  {
    name: 'not_contains',
    title: $localize`doesn't contain`,
    description: $localize`Does field lack value? Accepts types: Array, String`,
  },
  {
    name: 'not_starts_with',
    title: $localize`doesn't start with`,
    description: $localize`Does field not start with value? Accepts types: String`,
  },
  {
    name: 'not_ends_with',
    title: $localize`doesn't end with`,
    description: $localize`Does field not end with value? Accepts types: String`,
  },
  {
    name: 'equals_to',
    title: $localize`equals`,
    description: $localize`Is field equal to value? Must be same type. Accepts all data types.`,
  },
  {
    name: 'not_equals_to',
    title: $localize`does not equal`,
    description: $localize`Is field different from value? Accepts all data types.`,
  },
  {
    name: 'greater_than',
    title: $localize`greater than`,
    description: $localize`Is field greater than value? Accepts types: String, Date, Timestamp, Integer, Number, Boolean`,
  },
  {
    name: 'less_than',
    title: $localize`less than`,
    description: $localize`Is field less than value? Accepts types: String, Date, Timestamp, Integer, Number, Boolean`,
  },
  {
    name: 'is_true',
    title: $localize`is true`,
    description: $localize`Is field true? Accepts types: Boolean`,
    hide_rhs: true,
  },
  {
    name: 'is_not_true',
    title: $localize`is not true`,
    description: $localize`Is field false? Accepts types: Boolean`,
    hide_rhs: true,
  },
  {
    name: 'present',
    title: $localize`is present`,
    description: $localize`Is field a non-null value? Accepts all data types.`,
    hide_rhs: true,
  },
  {
    name: 'blank',
    title: $localize`is not present`,
    description: $localize`Is field a null value? Accepts all data types.`,
    hide_rhs: true,
  },
];

export const CONDITION_SCHEMA: [TextField, SelectFieldWithOptions, TextField] = [
  {
    name: 'lhs',
    type: 'string',
    control_type: 'text',
    label: $localize`Data field`,
  },
  {
    name: 'operand',
    type: 'string',
    control_type: 'select',
    label: $localize`Condition`,
    options: IF_CONDITIONS,
    placeholder: $localize`Select condition`,
  },
  {
    name: 'rhs',
    type: 'string',
    control_type: 'text',
    label: $localize`Value`,
  },
];

export const KEYWORDS_CONFIG: Keywords = {
  trigger: {
    block: true,
    config: true,
  },

  foreach: {
    block: true,
    config: [
      {
        name: 'source',
        type: 'string',
        control_type: 'list',
        label: $localize`Input list`,
        enforce_template_mode: true,
        hint: $localize`Input a list datapill.`,
      },
      {
        name: 'repeat_mode',
        type: 'string',
        control_type: 'select',
        label: $localize`Repeat mode`,
        options: [
          [$localize`One item at a time`, 'simple'],
          [$localize`Batch of items`, 'batch'],
        ],
        default: 'simple',
        hint: repeatModeHintFormatter({b: content => `<b>${content}</b>`}),
      },
      {
        name: 'batch_size',
        type: 'integer',
        control_type: 'integer',
        label: $localize`Batch size`,
        optional: true,
        hint: $localize`Defaults to 100 when the value is not specified OR when the value is less than 1`,
        ngIf: 'input.repeat_mode === "batch"',
      },
      {
        name: 'clear_scope',
        type: 'boolean',
        control_type: 'checkbox',
        optional: true,
        label: $localize`Clear step output`,
        hint: clearScopeHintFormatter({
          a: content =>
            `<a href='https://docs.workato.com/features/list-management.html#clear-step-output' target='_blank'>${content}</a>`,
          b: content => `<b>${content}</b>`,
        }),
      },
    ],
  },

  stop: {
    config: [
      {
        name: 'stop_with_error',
        type: 'boolean',
        control_type: 'select',
        options: [
          {
            name: 'false',
            title: $localize`Successful`,
          },
          {
            name: 'true',
            title: $localize`Failed`,
          },
        ],
        label: $localize`In job report, mark stopped job as`,
      },
      {
        name: 'stop_reason',
        type: 'string',
        control_type: 'text',
        label: $localize`Reason for failure`,
        hint: $localize`Error message to be displayed in the job report for each failed job.`,
        ngIf: 'input.stop_with_error === "true"',
      },
    ],
  },

  if: {
    block: true,
    config: [
      {
        type: 'array',
        of: 'object',
        name: 'conditions',
        properties: CONDITION_SCHEMA,
      },
    ],
  },

  elsif: {
    block: true,
    config: [
      {
        type: 'array',
        of: 'object',
        name: 'conditions',
        properties: CONDITION_SCHEMA,
      },
    ],
  },

  else: {
    block: true,
  },

  action: {
    block: false,
    config: true,
  },

  try: {
    block: true,
  },

  catch: {
    block: true,
    config: [
      {
        name: 'max_retry_count',
        type: 'number',
        control_type: 'select',
        label: $localize`Retry actions in Monitor block?`,
        hint: maxRetryCountHintFormatter({b: content => `<b>${content}</b>`}),
        default: 0,
        options: times(4).map(retryTimes => [retryTimesFormatter({retryTimes}), retryTimes] as PicklistItem),
      },
      {
        name: 'retry_interval',
        type: 'number',
        control_type: 'select',
        label: $localize`Time interval between retries`,
        hint: retryIntervalHintFormatter({b: content => `<b>${content}</b>`}),
        default: 2,
        options: times(10).map(
          index => [retryIntervalFormatter({retryInterval: index + 1}), index + 1] as PicklistItem,
        ),
        ngIf: 'input.max_retry_count > 0',
      },
    ],
  },

  repeat: {
    block: true,
  },

  while_condition: {
    config: [
      {
        type: 'array',
        of: 'object',
        name: 'conditions',
        properties: CONDITION_SCHEMA,
      },
    ],
  },
};
