<footer class="footer">
  <div class="footer__inner">
    <div class="footer__navigation">
      <div *ngFor="let group of mainLinks" class="footer__menu footer__menu_{{ group.name }}">
        <div class="footer__menu-title">{{ group.title }}</div>
        <ul class="footer__menu-list">
          <li *ngFor="let link of group.links" class="footer__menu-item">
            <a
              class="footer__menu-link"
              [href]="link.href"
              [attr.target]="link.target"
              [attr.rel]="link.rel"
              [wMixpanel]="link.title"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer__side">
      <div class="footer__terms">
        <span class="footer__terms-item">© Workato {{ currentYear }}</span>
        <span *ngFor="let link of sideLinks" class="footer__terms-item">
          <a
            class="footer__terms-link"
            [href]="link.href"
            [attr.target]="link.target"
            [attr.rel]="link.rel"
            [wMixpanel]="link.title"
            >{{ link.title }}</a
          >
        </span>
      </div>
      <div class="footer__trademark">
        The trademarks WORKATO®, WORKBOT® and WORK JAM® are registered in U.S. Patent & Trademark Office to Workato,
        Inc.
      </div>
      <div class="footer__social">
        <a class="footer__social-icon" href="mailto:info@workato.com" wMixpanel="Email">
          <w-svg-icon iconId="social/envelope" [size]="14" />
        </a>
        <a
          class="footer__social-icon"
          href="//facebook.com/workato"
          target="_blank"
          rel="noopener"
          wMixpanel="Facebook"
        >
          <w-svg-icon iconId="social/facebook" [size]="14" />
        </a>
        <a class="footer__social-icon" href="//twitter.com/workato" target="_blank" rel="noopener" wMixpanel="Twitter">
          <w-svg-icon iconId="social/twitter" [size]="14" />
        </a>
        <a
          class="footer__social-icon"
          href="//linkedin.com/company/workato"
          target="_blank"
          rel="noopener"
          wMixpanel="Linkedin"
        >
          <w-svg-icon iconId="social/linkedin" [size]="14" />
        </a>
      </div>
    </div>
  </div>
</footer>
