import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SvgIconComponent} from '@shared/components/svg-icon/svg-icon.component';
import {MixpanelService} from '@shared/services/mixpanel';

import {appSettings} from '../../app-settings';
import {MixpanelDirective} from '../../directives/mixpanel.directive';

import {footerLinks} from './footer-links';
import {FooterLink, FooterLinkGroup} from './footer.types';

const GUEST_SIDE_LINKS: FooterLink[] = [
  {
    title: 'Privacy',
    href: `${appSettings.websiteUrl}/legal/services-privacy-policy`,
  },
  {
    title: 'Legal',
    href: `${appSettings.websiteUrl}/legal`,
  },
  {
    title: 'Contact: (844) 469-6752',
    href: 'tel:+18444696752',
  },
];

@Component({
  selector: 'w-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SvgIconComponent, MixpanelDirective],
  providers: [MixpanelService],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  mainLinks: FooterLinkGroup[];
  sideLinks = GUEST_SIDE_LINKS;

  constructor(private mixpanelService: MixpanelService) {}

  ngOnInit() {
    this.mixpanelService.init({featureName: 'Footer navigation', prependRoute: false});
    this.mainLinks = footerLinks;
  }
}
