import {Injectable} from '@angular/core';

import {AuthUser} from './auth-user';

@Injectable({
  providedIn: 'root',
})
export class AgenticHelper {
  constructor(private authUser: AuthUser) {}

  get hasAgenticAccess(): boolean {
    return Boolean(this.authUser.has_agentic_access);
  }
}
