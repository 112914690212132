import {formatNumber} from '@shared/pipes/numeral.pipe';

import {ChartResourceGroup, UsageGroupBy, UsageResourceType, UsageScopeFilter} from './usage-dashboard.types';

export const TBP_PATH = 'tbp';
export const CBP_PATH = 'cbp';

export const TBP_MEMBERSHIP_TYPES = ['tbp_v1', 'tbp_v2'];
export const CBP_MEMBERSHIP_TYPES = ['cbp_v1'];

export const TASK_USAGE_CHART_MIN_Y_AXIS_VALUE = 50;
export const NEAR_LIMIT_MULTIPLIER = 0.85;

// TBP only

export const HVR_TIER_1_THRESHOLD = 4_000_000;
export const HVR_TIER_2_THRESHOLD = 15_000_000;
export const HVR_TIER_3_THRESHOLD = 30_000_000;

export const HVR_TIER_1_LABEL = `(${formatNumber(HVR_TIER_1_THRESHOLD, 'abbr')} - ${formatNumber(
  HVR_TIER_2_THRESHOLD,
  'abbr',
)} tasks)`;
export const HVR_TIER_2_LABEL = `(${formatNumber(HVR_TIER_2_THRESHOLD, 'abbr')} - ${formatNumber(
  HVR_TIER_3_THRESHOLD,
  'abbr',
)} tasks)`;
export const HVR_TIER_3_LABEL = `(>= ${formatNumber(HVR_TIER_3_THRESHOLD, 'abbr')} tasks)`;

export const SCOPE_ALLOWED_GROUP_BY: Record<UsageScopeFilter, UsageGroupBy[]> = {
  all_tasks: ['resource', 'environment'],
  recipe_tasks: ['recipe', 'project_folder', 'environment'],
  api_proxy_tasks: ['api_group', 'api_endpoint', 'environment'],
};

export const DEFAULT_SCOPE_GROUP_BY: Record<UsageScopeFilter, UsageGroupBy> = {
  all_tasks: 'resource',
  recipe_tasks: 'recipe',
  api_proxy_tasks: 'api_endpoint',
};

export const DELETED_RESOURCE_NAME_MAPPER: Record<UsageResourceType, string> = {
  environment: 'All deleted environments',
  recipe: 'All deleted recipes',
  folder: 'All deleted recipes',
  api_group: 'All deleted API endpoints',
  api_endpoint: 'All deleted API endpoints',
};

export const CHART_HVR_ELIGIBLE_TASKS_GROUP = 'hvr_eligible_tasks';
export const CHART_BILLABLE_TASKS_GROUP = 'billable_tasks';

export const CHART_SPECIAL_GROUP_TYPE_MAPPER: Record<string, ChartResourceGroup> = {
  [CHART_HVR_ELIGIBLE_TASKS_GROUP]: 'hvr_eligible_tasks',
  [CHART_BILLABLE_TASKS_GROUP]: 'billable_tasks',
};

export const CHART_SPECIAL_GROUP_NAME_MAPPER: Record<string, string> = {
  [CHART_HVR_ELIGIBLE_TASKS_GROUP]: 'Tasks from HVR eligible recipes',
  [CHART_BILLABLE_TASKS_GROUP]: 'Tasks from other assets',
};
