import _ from 'lodash';

import {stringifyUrlQuery} from '@shared/utils/stringify-url-query';

import {slugify} from '../../utils/slugify';

export const helpers = {
  parameterize: slugify,
  urlParam,
  urlQuery: stringifyUrlQuery,
  stringify: JSON.stringify,
  substring,
  lowerCase,
};

function urlParam(param: any): string {
  if (typeof param === 'string' || typeof param === 'number' || typeof param === 'boolean') {
    return encodeURIComponent(_.toString(param));
  } else {
    return '';
  }
}

function substring(val: string | undefined, start?: number, end?: number): string {
  return _.toString(val).slice(start, end);
}

function lowerCase(val: string | undefined): string {
  return _.toString(val).toLowerCase();
}
