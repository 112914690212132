import {IntlMessageFormat, PrimitiveType} from 'intl-messageformat';

import {MessageFormatter} from './build-message-formatter.types';

/**
 * Provide template and a context to create a i18n-friendly string
 * It supports ICU select, plural and selectordinal expression.
 * Limitations:
 * Don't pass raw HTML here, but use tags without attributes, e.g. <a></a> and provide a function in the context, e.g. a: parts => `<a href="test">${parts}></a>
 * Don't use interpolation like {value} outside ICU plural, or select expression because Angular will not extract such interpolations. Instead use { value, select, other {{value}} }
 */
export function buildMessageFormatter<
  TContext extends Record<string, PrimitiveType> | void = void,
  TTagFormatterName extends string | void = void,
>(template: string): MessageFormatter<TContext, TTagFormatterName> {
  const msg = new IntlMessageFormat(template, $localize.locale);

  return contextAndTagFormatters =>
    ((contextAndTagFormatters ? msg.format(contextAndTagFormatters) : msg.format()) as string)
      .replace(/\s+/g, ' ')
      .trim();
}
