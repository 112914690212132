import _ from 'lodash';
import URL from 'url-parse';
import {filter, map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {Event, NavigationEnd, ResolveEnd, Router} from '@angular/router';

import {PageSpinnerService} from '@shared/services/page-spinner.service';
import {MixpanelService} from '@shared/services/mixpanel';

import {PageService} from '../../services/page.service';
import {RouteData} from '../../types';
import {PerformanceService} from '../../modules/performance/performance.service';
import {RouterHelpers} from '../../services/router-helpers.service';

@Component({
  selector: 'w-router-outlet',
  templateUrl: './router-outlet.component.html',
})
export class RouterOutletComponent implements OnInit {
  lastNavigatedPath: string;
  initialNavigation = true;

  constructor(
    private router: Router,
    private page: PageService,
    private pageSpinner: PageSpinnerService,
    private performanceService: PerformanceService,
    private routerHelpers: RouterHelpers,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit() {
    this.router.initialNavigation();
    this.pageSpinner.show();

    this.router.events
      .pipe(
        filter((event: Event): event is ResolveEnd => event instanceof ResolveEnd),
        map((event: ResolveEnd) => this.getRouteUrl(event)),
      )
      .subscribe(this.handleRouteChange);

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      () => {
        this.scrollToTop();

        if (this.initialNavigation) {
          this.initialNavigation = false;
          this.pageSpinner.hide();
        }
      },
      () => {
        this.pageSpinner.hide();
      },
    );
  }

  getRouteUrl(event: ResolveEnd): string {
    return event.url;
  }

  handleRouteChange = (url: string) => {
    const data = this.routerHelpers.routeData;

    this.performanceService.reportPageView(url.split('?')[0]);

    if (!data.skipTitleChange) {
      this.updatePageTitle(data);
    }

    this.updatePageLayout(data, url);

    this.mixpanelService.track('Page: opened', {
      page: _.isFunction(this.routerHelpers.routeData.mixpanelRouteName)
        ? this.routerHelpers.routeData.mixpanelRouteName(url)
        : this.routerHelpers.routeData.mixpanelRouteName,
    });
  };

  private updatePageTitle(data: RouteData) {
    this.page.title = _.isFunction(data.title) ? data.title(data) : data.title!;
  }

  private updatePageLayout({size, pageClass, isFullscreenPage}: RouteData, url: string) {
    if (typeof pageClass === 'string') {
      this.page.setClass(pageClass);
    } else {
      this.page.setDefaultClass();
    }

    this.page.toggleFullscreenPage(
      typeof isFullscreenPage === 'function' ? isFullscreenPage(url) : Boolean(isFullscreenPage),
    );
    this.page.setPageSize(size);
  }

  private scrollToTop() {
    const {pathname} = new URL(this.router.url);

    // Scroll to top only when path has changed
    if (pathname !== this.lastNavigatedPath) {
      window.scrollTo(0, 0);
      this.lastNavigatedPath = pathname;
    }
  }
}
