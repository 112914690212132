import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  Optional,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {debounce, distinctUntilChanged} from 'rxjs/operators';
import {trigger} from '@angular/animations';
import {interval} from 'rxjs';

import {subscriptions} from '@shared/services/subscriptions';
import {LayoutService} from '@shared/services/layout.service';
import {ElementResizeDirective} from '@shared/directives/element-resize.directive';
import {KeyboardNavigationDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation.directive';
import {KeyboardNavigationItemDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item.directive';
import {GlobalCaptureEventListeners} from '@shared/services/global-capture-event-listeners.service';
import {MixpanelService} from '@shared/services/mixpanel';

import {SharedModule} from '../../shared.module';
import {slideInOut} from '../../animations/slide-in-out.animation';
import {stringifyRouterPath} from '../../utils/stringify-router-url';
import {AppLayoutTheme} from '../../types';

import {AppSidebarLogoDirective} from './app-sidebar-logo.directive';
import {AppNavItem, AppSidebarGroup, AppSidebarItem, AppSidebarLink} from './app-sidebar.types';
import {AppSidebarService, DESKTOP_SIDEBAR_TOGGLE_DELAY} from './app-sidebar.service';

@Component({
  selector: 'w-app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrl: './app-sidebar.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('slideInOut', slideInOut)],
  standalone: true,
  imports: [SharedModule, KeyboardNavigationItemDirective, ElementResizeDirective],
  providers: [MixpanelService],
})
export class AppSidebarComponent implements OnInit {
  @Input({required: true}) theme: AppLayoutTheme;
  @Input({required: true}) items: AppSidebarItem[];
  @Input() links: AppSidebarLink[] = [];
  @Input() linksOffset = 0;

  @ContentChild(AppSidebarLogoDirective, {read: TemplateRef}) customLogoTemplate?: TemplateRef<{$implicit: boolean}>;

  isOpened = false;
  isMobileLayout = false;
  hasVerticalScrollbar = false;

  expandedSections = new Set<AppSidebarGroup>();

  private subs = subscriptions();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private globalEvents: GlobalCaptureEventListeners,
    private appSidebarService: AppSidebarService,
    private layoutService: LayoutService,
    private cd: ChangeDetectorRef,
    private mixpanelService: MixpanelService,
    @Optional() private keyboardNavigation?: KeyboardNavigationDirective,
  ) {}

  ngOnInit() {
    this.mixpanelService.init({featureName: 'Header navigation', prependRoute: false});
    this.subs.add(
      this.layoutService.isMobileLayout$.subscribe(isMobileLayout => {
        this.isMobileLayout = isMobileLayout;
        this.cd.markForCheck();
      }),
      this.appSidebarService.isSidebarOpened$
        .pipe(
          debounce(() => interval(this.isMobileLayout ? 0 : DESKTOP_SIDEBAR_TOGGLE_DELAY)),
          distinctUntilChanged(),
        )
        .subscribe(isOpened => {
          if (isOpened) {
            // Move focus inside the sidebar once it's open
            this.keyboardNavigation?.autofocus();
            this.globalEvents.add('keydown.esc', this.handleEscapeKeydown);
          } else {
            this.expandedSections.clear();
            this.globalEvents.remove('keydown.esc', this.handleEscapeKeydown);
          }

          this.isOpened = isOpened;
          this.cd.markForCheck();
        }),
    );
  }

  closeSidebar() {
    this.appSidebarService.closeSidebar();
  }

  closeSidebarForDesktop() {
    if (this.isMobileLayout) {
      return;
    }

    this.appSidebarService.closeSidebar();
  }

  openSidebarForDesktop() {
    if (this.isMobileLayout) {
      return;
    }

    this.appSidebarService.openSidebar();
  }

  toggleSection(item: AppSidebarGroup) {
    if (this.expandedSections.has(item)) {
      this.expandedSections.delete(item);
    } else {
      this.expandedSections.add(item);
    }

    this.appSidebarService.openSidebar();
  }

  isActiveItem(item: AppNavItem): boolean {
    const currentPath = location.pathname;
    const isActive = item.routerUrl.path ? currentPath.startsWith(stringifyRouterPath(item.routerUrl.path)) : false;

    if (!isActive && item.alsoActiveFor) {
      return item.alsoActiveFor.some(regexp => regexp.test(currentPath));
    }

    return isActive;
  }

  isActiveGroup(group: AppSidebarGroup): boolean {
    return this.expandedSections.has(group) || group.items.some(item => this.isActiveItem(item));
  }

  updateScrollbarVisibility(element: HTMLElement) {
    this.hasVerticalScrollbar = element.scrollHeight > element.offsetHeight;
  }

  handleItemSelected(parentSection?: KeyboardNavigationItemDirective) {
    parentSection?.activate();
    this.appSidebarService.closeSidebar();
  }

  handleFocusOut({relatedTarget}: FocusEvent) {
    // Ignore focusout events if the new focused element is still within the sidebar
    if (relatedTarget instanceof Element && this.elementRef.nativeElement.contains(relatedTarget)) {
      return;
    }

    this.appSidebarService.closeSidebar();
  }

  private handleEscapeKeydown = () => {
    this.closeSidebar();
  };
}
