import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HotkeysCheatsheetComponent} from 'angular2-hotkeys';
import {Settings} from 'luxon';

import {GlobalCaptureEventListeners} from '@shared/services/global-capture-event-listeners.service';
import {RootMixpanelService} from '@shared/services/root-mixpanel.service';

import {ServicePagesService} from '../../services/service-pages.service';
import {UserSessionService} from '../../services/user-session.service';
import {AuthUser} from '../../services/auth-user';
import {OemService} from '../../modules/oem/oem.service';
import {KeyManagementDataService} from '../../pages/team-members/workspace-settings/environment-settings-key-tab/key-management-data.service';
import {ThemeService} from '../../theming/theme.service';
import {GlobalHotkeys} from '../../services/global-hotkeys.service';
import {AccountPeriod} from '../../services/account-period.service';
import {GlobalBannersService} from '../../services/global-banners.service';
import {suppressImeEvent} from '../../utils/suppress-ime-event';
import {PageService} from '../../services/page.service';
import {stripIdsFromUrlPath} from '../../utils/strip-ids-from-url-path';
import {RouterHelpers} from '../../services/router-helpers.service';
import {DateFormatService} from '../../services/date-format.service';
import {CopilotHelper} from '../../services/copilot-helper.service';

@Component({
  selector: 'w-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(HotkeysCheatsheetComponent, {static: true}) hotkeyCheatsheet: HotkeysCheatsheetComponent;

  alerts = window.Workato.alerts;

  constructor(
    private servicePages: ServicePagesService,
    private userSession: UserSessionService,
    private authUser: AuthUser,
    private themeService: ThemeService,
    private oemService: OemService,
    private globalHotkeys: GlobalHotkeys,
    private keyManagementDataService: KeyManagementDataService,
    private accountPeriod: AccountPeriod,
    private copilotHelper: CopilotHelper,
    private globalBannersService: GlobalBannersService,
    private globalEvents: GlobalCaptureEventListeners,
    private pageService: PageService,
    private routerHelpers: RouterHelpers,
    private dateFormatService: DateFormatService,
    private rootMixpanelService: RootMixpanelService,
  ) {}

  async ngOnInit() {
    try {
      await this.authUser.init();
    } catch (err) {
      this.themeService.init();
      this.servicePages.showPageLoadError({err});

      return;
    }

    this.rootMixpanelService.initRouteTracking();
    this.accountPeriod.init();
    this.initDateTimeSettings();
    this.dateFormatService.init(this.authUser.datetime_format);
    this.oemService.initialize();
    this.themeService.init();
    this.globalHotkeys.init(this.hotkeyCheatsheet);
    this.userSession.init();
    this.keyManagementDataService.initialize();
    this.globalBannersService.init();
    this.globalEvents.add('keydown', suppressImeEvent);

    if (this.copilotHelper.hasSomeAiFeaturesEnabled) {
      this.copilotHelper.setAiMixpanelProperties();
    }

    /**
     * Setting current route path and hash as HTML attributes on body element
     * to allow Pendo or other 3rd party widgets to change their styling accordingly
     */
    this.routerHelpers.routeChange$.subscribe(() => {
      this.pageService.addDataAttribute('routePath', stripIdsFromUrlPath(window.location.pathname));
      this.pageService.addDataAttribute('routeHash', window.location.hash.slice(1));
      this.pageService.addDataAttribute('routeQuery', window.location.search.slice(1));
    });

    if (window.Workato.pageError) {
      this.servicePages.showPageError({type: window.Workato.pageError.code});
    }
  }

  ngOnDestroy() {
    this.globalEvents.remove('keydown', suppressImeEvent);
  }

  private initDateTimeSettings() {
    Settings.defaultZone = this.authUser.timezone;
    Settings.defaultLocale = 'en-US';
  }
}
