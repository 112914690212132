import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import cls from 'classnames';

import {OemService} from '../modules/oem/oem.service';

import {GlobalHotkeys} from './global-hotkeys.service';
import {AuthUser} from './auth-user';

export const OEM_THEME = 'oem-theme';
export const OEM_BRANDING_SIDEBAR_MARGIN = 'oem-branding-sidebar-margin';

const PRESERVED_BODY_CLASSES: string[] = [
  'widget-page',
  'user-page',
  'guest-page',
  'portals-page',
  'dark-theme',
  'light-theme',
  OEM_BRANDING_SIDEBAR_MARGIN,
  OEM_THEME,
];

const DEFAULT_PAGE_CLASS = 'w-page-global';

const PAGE_SIZE_CLASS_MAP = new Map<PageSize, string>([
  ['full', 'w-page-global_size_full'],
  ['large', 'w-page-global_size_large'],
  ['medium', 'w-page-global_size_medium'],
  ['small', 'w-page-global_size_small'],
]);

export type PageSize =
  | 'full' // A layout type
  | 'large' // B layout type
  | 'medium' // C layout type
  | 'small'; // D layout type

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private pageElem = document.body;

  constructor(
    private ngTitle: Title,
    private oemService: OemService,
    private globalHotkeys: GlobalHotkeys,
    private authUser: AuthUser,
  ) {}

  get title(): string {
    return this.ngTitle.getTitle();
  }

  set title(title: string) {
    const fullTitle = title ? `${title} | ${this.product}` : `${this.product} - Connect your apps. Automate your work.`;

    this.ngTitle.setTitle(fullTitle);
  }

  setClass(pageClass: string): this {
    const currentClasses = this.pageElem.classList;

    this.pageElem.className = cls(
      // Adding classes for current route
      pageClass,
      // Preserving classes, set by server:
      PRESERVED_BODY_CLASSES.filter(className => currentClasses.contains(className)),
    );

    return this;
  }

  setDefaultClass(): this {
    this.setClass(DEFAULT_PAGE_CLASS);

    return this;
  }

  addClass(pageClass: string): this {
    this.pageElem.classList.add(...pageClass.split(' '));

    return this;
  }

  removeClass(pageClass: string): this {
    this.pageElem.classList.remove(...pageClass.split(' '));

    return this;
  }

  addDataAttribute(name: string, value: string): this {
    this.pageElem.dataset[name] = value;

    return this;
  }

  toggleFullscreenPage(isFullscreenPage: boolean): this {
    return this.toggleFullscreen(isFullscreenPage, 'page');
  }

  toggleFullscreenContent(isFullscreenContent: boolean): this {
    return this.toggleFullscreen(isFullscreenContent, 'content');
  }

  setPageSize(size: PageSize = 'full') {
    PAGE_SIZE_CLASS_MAP.forEach((value, key) => {
      this.pageElem.classList.toggle(value, key === size);
    });
  }

  // Temporarily disables all CSS transitions on the page
  disableTransitions(duration = 0) {
    this.addClass('no-transition');
    setTimeout(() => this.removeClass('no-transition'), duration);
  }

  private get product(): string {
    return this.oemService.appName || (this.authUser.agentic ? 'Agentic' : 'Workato');
  }

  private toggleFullscreen(isFullscreen: boolean, mode: 'content' | 'page'): this {
    const className = mode === 'page' ? 'fullscreen-page-root' : 'fullscreen-content';

    this.pageElem.classList.toggle(className, isFullscreen);
    this.globalHotkeys.toggleAll(!isFullscreen);

    return this;
  }
}
